import {useEffect, useRef} from 'react';
import type {LinksFunction} from '@remix-run/server-runtime';
import cn from 'classnames';

import {twMerge} from '@/stylesheets/twMerge';
import useLottieWeb from '@/hooks/useLottieWeb';
import {useMediaQuery} from '@/hooks/useMediaQuery';

import type {BaseIconProps} from '../../types';

import MagicLottie from './magic-stars.json';
import stylesheet from './styles.css?url';

export interface MagicIconOptions {
  /**
   * Offset when the animation starts as the icon enters the screen. Default is -200px.
   */
  animationOffset?: string;

  /**
   * Dynamically set fill based on color from parent. Default is false.
   */
  fillCurrent?: boolean;

  /**
   * Loop the animation once it starts to play. Default is true.
   */
  loopAnimation?: boolean;
}

export const links: LinksFunction = () => [
  {rel: 'stylesheet', href: stylesheet},
];

export interface MagicIconProps extends BaseIconProps {
  options?: MagicIconOptions;
  style?: React.CSSProperties;
}

type Props = Omit<MagicIconProps, 'icon'>;

export default function MagicIcon({
  className,
  options = {
    animationOffset: '-200px',
    fillCurrent: false,
    loopAnimation: true,
  },
  size,
  style,
}: Props) {
  const lottie = useLottieWeb();
  const elementRef = useRef<HTMLDivElement>(null);
  const prefersReducedMotion = useMediaQuery(
    '(prefers-reduced-motion: reduce)',
  );

  useEffect(() => {
    if (!lottie) return;

    const instance = lottie.loadAnimation({
      animationData: MagicLottie,
      autoplay: false,
      container: elementRef.current!,
      loop: options.loopAnimation,
      renderer: 'svg',
    });

    instance.pause();

    // Leave the animation paused if user prefers reduced motion
    if (prefersReducedMotion) return;

    // Play the animation when icon enters the screen
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            instance.play();

            // No need to continue to observe, leave animation playing
            observer.unobserve(entry.target);
          }
        });
      },
      {
        // Offset when the animation plays as it enters the screen
        rootMargin: options.animationOffset,
      },
    );

    observer.observe(elementRef.current!);

    return () => {
      instance.destroy();
      observer.disconnect();
    };
  }, [
    elementRef,
    lottie,
    options.animationOffset,
    options.loopAnimation,
    prefersReducedMotion,
  ]);

  return (
    <div
      className={twMerge(
        cn('inline-block', className, {
          'fill-current': options.fillCurrent,
        }),
      )}
      ref={elementRef}
      style={{width: size, height: size, ...style}}
    ></div>
  );
}
